<template>
    <v-stepper-content :step="step">
    <v-card :loading="loading">
      <br />
      <br />
      با تشکر از شما 
      <br />
      <br />
      <div v-if="loading">
      لطفا کمی صبر نمایید!
      </div>
      </v-card>
    </v-stepper-content>  
</template>

<script>
  export default {
    data: () => ({
    }),
    props:['step','loading'],
  }
</script>
