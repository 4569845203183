<template>
    <v-stepper-content :step="step">
      <v-form ref="form">
        <v-card
          class="mb-12"
          height="300px"
        >
        کدام تبلیغ تلویزیونی را به یاد می آورید!؟
        <br />
          <v-text-field
            v-model="ad_name"
            label="نام تبلیغ"
            :rules="[v => !!v || 'فیلد اجباری']"
          ></v-text-field>
             
        </v-card>
         <v-btn
          color="primary"
           @click="stepper"
         >
           ادامه
         </v-btn>
      </v-form>
    </v-stepper-content>  
</template>

<script>
  export default {
    data: () => ({
      ad_name:'',
    }),
    props:['step'],
    methods:{
      stepper(){
        if( this.$refs.form.validate() ){
          this.$emit('stepper',{ad_name:this.ad_name})
        }
      }
    }
  }
</script>
