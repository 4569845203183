<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="150"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          نظرسنجی
        </h1>

        <p class="subheading font-weight-regular">
          <br>هدف از این نظرسنجی برنامه ریزی برای تبلیغات در برنامه های تلویزیونی می‌باشد و تمام اطلاعات نزد ما محفوظ می ماند.
        </p>
      </v-col>

      <v-col
        class="mb-8"
        cols="12"
      >
        <v-row justify="center">
          <steps />
        </v-row>
      </v-col>



    </v-row>
  </v-container>
</template>

<script>
  import Steps from './Steps';

  export default {
    name: 'HelloWorld',
    components: {
      Steps,
    },
    data: () => ({
      
    }),
  }
</script>
