<template>
    <v-stepper-content :step="step">
      <v-form ref="form">
        <v-card
          class="mb-12"
          
        >
       در طول روز تلویزیون شما روی چه شبکه ای قرار دارد!؟
        <v-radio-group v-model="tv_channel" @change="stepper"
          :rules="[v => !!v || 'فیلد اجباری']"
          >
          <v-radio
            label="شبکه یک"
            :value="1"
          ></v-radio>
          <v-radio
            label="شبکه دو"
            :value="2" 
          ></v-radio>
          <v-radio
            label="شبکه سه"
            :value="3"  
          ></v-radio>
          <v-radio
            label="شبکه ورزش"
            :value="4" 
          ></v-radio>
          <v-radio
            label="سایر شبکه های داخلی"
            :value="5" 
          ></v-radio>
          <v-radio
            label="شبکه های خارجی"
            :value="6" 
          ></v-radio>
        </v-radio-group>
        </v-card>
      </v-form>
    </v-stepper-content>  
</template>

<script>
  export default {
    data: () => ({
      tv_channel:-1,
    }),
    props:['step'],
    methods:{
      stepper(){
        if( this.$refs.form.validate() ){
          this.$emit('stepper',{tv_channel:this.tv_channel})
        }
      }
    }
  }
</script>
