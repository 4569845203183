<template>
    <v-stepper-content :step="step">
      <v-form ref="form">
        <v-card
          class="mb-12"
        >
         برنامه های مورد علاقه شما در تلویزیون چیست!؟
          <v-radio-group v-model="movie_type"   :rules="[v => !!v || 'فیلد اجباری']" >
            <v-radio
              label="سریال های تلویزیونی"
              :value="1"
            ></v-radio>
            <v-radio
              label="مستند ها و مسابقات تلویزیونی"
              :value="2" 
            ></v-radio>
            <v-radio
              label="فوتبال و برنامه های ورزشی"
              :value="3"  
            ></v-radio>
          </v-radio-group>
          <v-text-field
            :rules="[v => !!v || 'فیلد اجباری']"
            v-if="movie_type > 0"
            :label="movie_label"
            v-model="movie_name"
          ></v-text-field>
             
        </v-card>
         <v-btn
          color="primary"
           @click="stepper"
         >
           ادامه
         </v-btn>
      </v-form>
    </v-stepper-content>  
</template>

<script>
  export default {
    data: () => ({
      movie_type:-1,
      movie_name:'',
    }),
    props:['step'],
    computed:{
      movie_label(){
        if(this.movie_type==1){
          return 'نام سریال مورد علاقه شما';
        }
        if(this.movie_type==2){
          return 'نام مستند مورد علاقه شما';
        }
        if(this.movie_type==3){
          return 'نام برنامه ورزشی مورد علاقه شما';
        }
        return '';
      }
    },
    methods:{
      stepper(){
        if( this.$refs.form.validate() ){
          this.$emit('stepper',{movie_type:this.movie_type,movie_name:this.movie_name})
        }
      }
    }
  }
</script>
