
const Cities=[
  'آذرشهر',
  'اسكو',
  'اهر',
  'بستان آباد',
  'بناب',
  'تبريز',
  'جلفا',
  'چار اويماق',
  'سراب',
  'شبستر',
  'عجبشير',
  'كليبر',
  'مراغه',
  'مرند',
  'ملكان',
  'ميانه',
  'ورزقان',
  'هريس',
  'هشترود',
  'اروميه',
  'اشنويه',
  'بوكان',
  'پير انشهر',
  'تكاب',
  'چالدران',
  'خوي',
  'سردشت',
  'سلماس',
  'شاهين دژ',
  'ماكو',
  'مهاباد',
  'مياندوآب',
  'نقده',
  'اردبيل',
  'بيله سوار',
  'پارس آباد',
  'خلخال',
  'كوثر',
  'گرمي',
  'مشگين',
  'نمين',
  'نير',
  'آران و بيدگل',
  'اردستان',
  'اصفهان',
  'برخوار و ميمه',
  'تيران و كرون',
  'چادگان',
  'خميني شهر',
  'خوانسار',
  'سميرم',
  'شاهين شهر و ميمه',
  'شهر رضا',
  'سميرم سفلي',
  'فريدن',
  'فريدون شهر',
  'فلاورجان',
  'كاشان',
  'گلپايگان',
  'لنجان',
  'مباركه',
  'نائين',
  'نجف آباد',
  'نطنز',
  'ساوجبلاق',
  'كرج',
  'نظرآباد',
  'طالقان',
  'آبدانان',
  'ايلام',
  'ايوان',
  'دره شهر',
  'دهلران',
  'شيران و چرداول',
  'مهران',
  'بوشهر',
  'تنگستان',
  'جم',
  'دشتستان',
  'دشتي',
  'دير',
  'ديلم',
  'كنگان',
  'گناوه',
  'اسلام شهر',
  'پاكدشت',
  'تهران',
  'دماوند',
  'رباط كريم',
  'ري',
  'شميرانات',
  'شهريار',
  'فيروزكوه',
  'ورامين',
  'اردل',
  'بروجن',
  'شهركرد',
  'فارسان',
  'كوهرنگ',
  'لردگان',
  'بيرجند',
  'درميان',
  'سرايان',
  'سر بيشه',
  'فردوس',
  'قائن',
  'نهبندان',
  'برد سكن',
  'بجستان',
  'تايباد',
  'تحت جلگه',
  'تربت جام',
  'تربت حيدريه',
  'چناران',
  'جغتاي',
  'جوين',
  'خليل آباد',
  'خواف',
  'درگز',
  'رشتخوار',
  'زاوه',
  'سبزوار',
  'سرخس',
  'فريمان',
  'قوچان',
  'طرقبه و شانديز',
  'كاشمر',
  'كلات',
  'گناباد',
  'مشهد',
  'مه ولات',
  'نيشابور',
  'اسفراين',
  'بجنورد',
  'جاجرم',
  'شيروان',
  'فاروج',
  'مانه و سملقان',
  'آبادان',
  'اميديه',
  'انديمشك',
  'اهواز',
  'ايذه',
  'باغ ملك',
  'بندرماهشهر',
  'بهبهان',
  'خرمشهر',
  'دزفول',
  'دشت آزادگان',
  'رامشير',
  'رامهرمز',
  'شادگان',
  'شوش',
  'شوشتر',
  'گتوند',
  'لالي',
  'مسجد سليمان',
  'هنديجان',
  'ابهر',
  'ايجرود',
  'خدابنده',
  'خرمدره',
  'زنجان',
  'طارم',
  'ماه نشان',
  'دامغان',
  'سمنان',
  'شاهرود',
  'گرمسار',
  'مهدي شهر',
  'ايرانشهر',
  'چابهار',
  'خاش',
  'دلگان',
  'زابل',
  'زاهدان',
  'زهك',
  'سراوان',
  'سرباز',
  'كنارك',
  'نيكشهر',
  'آباده',
  'ارسنجان',
  'استهبان',
  'اقليد',
  'بوانات',
  'پاسارگاد',
  'جهرم',
  'خرم بيد',
  'خنج',
  'داراب',
  'زرين دشت',
  'سپيدان',
  'شيراز',
  'فراشبند',
  'فسا',
  'فيروزآباد',
  'قير و كارزين',
  'كازرون',
  'لارستان',
  'لامرد',
  'مرودشت',
  'ممسني',
  'مهر',
  'ني ريز',
  'آبيك',
  'البرز',
  'بوئين زهرا',
  'تاكستان',
  'قزوين',
  'قم',
  'بانه',
  'بيجار',
  'ديواندره',
  'سروآباد',
  'سقز',
  'سنندج',
  'قروه',
  'كامياران',
  'مريوان',
  'بافت',
  'بردسير',
  'بم',
  'جيرفت',
  'راور',
  'رفسنجان',
  'رودبار جنوب',
  'زرند',
  'سيرجان',
  'شهر بابك',
  'عنبرآباد',
  'قلعه گنج',
  'كرمان',
  'كوهبنان',
  'كهنوج',
  'منوجان',
  'اسلام آباد غرب',
  'پاوه',
  'ثلاث باباجاني',
  'جوانرود',
  'دالاهو',
  'روانسر',
  'سر پل ذهاب',
  'سنقر',
  'صحنه',
  'قصر شيرين',
  'كرمانشاه',
  'كنگاور',
  'گيلان غرب',
  'هرسين',
  'بوير احمد',
  'بهمئي',
  'دنا',
  'كهگيلويه',
  'گچساران',
  'آزادشهر',
  'آق قلا',
  'بندر گز',
  'بندر تركمن',
  'راميان',
  'علي آباد',
  'كرد كوي',
  'كلاله',
  'گرگان',
  'گنبد كاووس',
  'مينو دشت',
  'آستارا',
  'آستانه اشرفيه',
  'املش',
  'بندر انزلي',
  'رشت',
  'رضوانشهر',
  'رودبار',
  'رودسر',
  'سياهكل',
  'شفت',
  'صومعه سرا',
  'طوالش',
  'فومن',
  'لاهيجان',
  'لنگرود',
  'ماسال',
  'ازنا',
  'اليگودرز',
  'بروجرد',
  'پلدختر',
  'خرم آباد',
  'دورود',
  'لامرد',
  'مرودشت',
  'ممسني',
  'مهر',
  'ني ريز',
  'آبيك',
  'البرز',
  'بوئين زهرا',
  'تاكستان',
  'قزوين',
  'قم',
  'بانه',
  'بيجار',
  'ديواندره',
  'سروآباد',
  'سقز',
  'سنندج',
  'قروه',
  'كامياران',
  'مريوان',
  'بافت',
  'بردسير',
  'بم',
  'جيرفت',
  'راور',
  'رفسنجان',
  'رودبار جنوب',
  'زرند',
  'سيرجان',
  'شهر بابك',
  'عنبرآباد',
  'قلعه گنج',
  'كرمان',
  'كوهبنان',
  'كهنوج',
  'منوجان',
  'اسلام آباد غرب',
  'پاوه',
  'ثلاث باباجاني',
  'جوانرود',
  'دالاهو',
  'روانسر',
  'سر پل ذهاب',
  'سنقر',
  'صحنه',
  'قصر شيرين',
  'كرمانشاه',
  'كنگاور',
  'گيلان غرب',
  'هرسين',
  'بوير احمد',
  'بهمئي',
  'دنا',
  'كهگيلويه',
  'گچساران',
  'آزادشهر',
  'آق قلا',
  'بندر گز',
  'بندر تركمن',
  'راميان',
  'علي آباد',
  'كرد كوي',
  'كلاله',
  'گرگان',
  'گنبد كاووس',
  'مينو دشت',
  'آستارا',
  'آستانه اشرفيه',
  'املش',
  'بندر انزلي',
  'رشت',
  'رضوانشهر',
  'رودبار',
  'رودسر',
  'سياهكل',
  'شفت',
  'صومعه سرا',
  'طوالش',
  'فومن',
  'لاهيجان',
  'لنگرود',
  'ماسال',
  'ازنا',
  'اليگودرز',
  'بروجرد',
  'پلدختر',
  'خرم آباد',
  'دورود',
  'دلفان',
  'سلسله',
  'كوهدشت',
  'الشتر',
  'نورآباد',
  'آمل',
  'بابل',
  'بابلسر',
  'بهشهر',
  'تنكابن',
  'جويبار',
  'چالوس',
  'رامسر',
  'ساري',
  'سوادكوه',
  'قائم شهر',
  'گلوگاه',
  'محمود آباد',
  'نكا',
  'نور',
  'نوشهر',
  'فريدونكنار',
  'آشتيان',
  'اراك',
  'تفرش',
  'خمين',
  'دليجان',
  'زرنديه',
  'ساوه',
  'شازند',
  'كميجان',
  'محلات',
  'بندرعباس',
  'ميناب',
  'بندر لنگه',
  'رودان-دهبارز',
  'جاسك',
  'قشم',
  'حاجي آباد',
  'ابوموسي',
  'بستك',
  'گاوبندي',
  'خمير',
  'اسدآباد',
  'بهار',
  'تويسركان',
  'رزن',
  'كبودر آهنگ',
  'ملاير',
  'نهاوند',
  'همدان',
  'ابركوه',
  'اردكان',
  'بافق',
  'تفت',
  'خاتم',
  'صدوق',
  'طبس',
  'مهريز',
  'ميبد',
'یزد',
]

export default Cities;
