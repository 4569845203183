<template>
  <v-stepper v-model="step" >
    <v-stepper-header>
      <v-stepper-step 
        v-for="n in 6" :step="n" 
        :complete="step==6 || step > n"
        :key="n"
       />  
    </v-stepper-header>

    <v-stepper-items>
      <component 
        v-for="i in 6" 
        :step="i" 
        :is="'Step'+i" 
        :key="i" 
        :loading="loading"
        @stepper="stepper"
        />
    </v-stepper-items>
  
  </v-stepper>
</template>

<script>
  import Step1 from './Step1';
  import Step2 from './Step2';
  import Step3 from './Step3';
  import Step4 from './Step4';
  import Step5 from './Step5';
  import Step6 from './Step6';


  export default {
    name: 'Steps',
    data () {
      return {
        step:1,
        loading:true,
        fields:{}
      }
    },
    components:{
      Step1,  Step2 , Step3 , Step4, Step5, Step6
    },
    
    methods:{
      stepper(e){
        this.fields = {...this.fields, ...e};
        this.step++;
        if(this.step>5){
          this.axios.post('submit.php',this.fields)
          .then( (response) =>{
            this.loading = false;
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
        }
      },
      submit(){
        this.step = 7;
      }
    }
  }
</script>
