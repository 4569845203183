<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        نظرسنجی
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="results"
        target="_blank"
        text
      >
        <span class="mr-2">نتایج</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <Poll/>
    </v-main>
  </v-app>
</template>
<style>
  @font-face {
  font-family: 'BTraffic';
    src: url('fonts/BTraffic.eot?#') format('eot'),
    url('fonts/BTraffic.ttf') format('truetype'),
    url('fonts/BTraffic.woff') format('woff');
  }
  *{
    font-family: BTraffic,'BTraffic',tahoma;
    font-size:18px;
  }
  .v-label{
    font-size:28px;
  }
  .v-btn__content{
    font-size:28px;
    margin-top: 6px;
  }
  .v-btn:not(.v-btn--round).v-size--default{
        height: 42px;
  }
  .v-text-field--outlined .v-label {
    top: 20px;
    font-size: 20px;
  }
  .v-input input {
    max-height: 32px;
    font-size: 24px;
    text-align: right;
  } 
  .v-input .v-label {
    height: 28px;
    line-height: 28px;
    letter-spacing: normal;
  }
  .subheading{
    font-size:18px;
  }
  .v-application--is-ltr .v-stepper__step__step{
    margin-right: 8px;
    font-size: 16px;
    padding-top: 4px;
    padding-left: 1px;
  }
</style>
<script>
import Poll from './components/Poll';

export default {
  name: 'App',

  components: {
    Poll,
  },

  data: () => ({
    //
  }),
};
</script>
