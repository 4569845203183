<template>
    <v-stepper-content :step="step">
      <v-form ref="form">
          <v-card
            class="mb-12"
          >   
          <v-combobox
                v-model="city"
                :items="cities"
                outlined
                label="نام شهر"
                :rules="[v => !!v || 'فیلد اجباری']"
              ></v-combobox>
              
              <br />
              <v-slider
                v-model="age"
                label="سن شما؟"
                thumb-color="red"
                thumb-label="always"
                :rules="[v => v > 10 || 'فیلد اجباری']"
              ></v-slider>
              
              <br />
              <v-radio-group
                v-model="sex"
                row
                :rules="[v => !!v || 'فیلد اجباری']"
              >
                <v-radio
                  label="خانم"
                  value="female"
                ></v-radio>
                <v-radio
                  label="آقا"
                  value="male"
                ></v-radio>
              </v-radio-group>
              </v-card>

          <v-btn
            color="primary"
            @click="stepper"
          >
            ادامه
          </v-btn>
        </v-form>
    </v-stepper-content>  
</template>

<script>
  import Cities from './Cities.js';
  export default {
    data: () => ({
      city:'',
      age:0,
      sex:null,
      cities:Cities,
    }),
    props:['step'],
    methods:{
      stepper(){
        if( this.$refs.form.validate() ){
          this.$emit('stepper',{city:this.city,age:this.age,sex:this.sex})
        }
      }
    }
  }
</script>
