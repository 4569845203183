<template>
    <v-stepper-content :step="step">
      <v-form ref="form">
        <v-card
          class="mb-12"
          
        >
        شما در طول روز چند ساعت تلویزیون تماشا می کنید!؟
        <v-radio-group v-model="tv_hours" @change="stepper"
          :rules="[v => !!v || 'فیلد اجباری']"
        >
          <v-radio
            label="یک ساعت"
            :value="1"
          ></v-radio>
          <v-radio
            label="دو ساعت"
            :value="2" 
          ></v-radio>
          <v-radio
            label="بیشتر از دو ساعت"
            :value="3"  
          ></v-radio>
          <v-radio
            label="معمولا نگاه نمیکنم"
            :value="4" 
          ></v-radio>
        </v-radio-group>

        </v-card>
      </v-form>
    </v-stepper-content>  
</template>

<script>
  export default {
    data: () => ({
      tv_hours:-1,
    }),
    props:['step'],
    methods:{
      stepper(){
        if( this.$refs.form.validate() ){
          this.$emit('stepper',{tv_hours:this.tv_hours})
        }
      }
    }
  }
</script>
